import { render, staticRenderFns } from "./provice.vue?vue&type=template&id=2554f686&scoped=true&"
import script from "./provice.vue?vue&type=script&lang=js&"
export * from "./provice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2554f686",
  null
  
)

export default component.exports