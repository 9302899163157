<template>
  <div class="cascader">
    <a-cascader
      style="width:100%"
      :options="options"
      :allowClear="allowClear"
      :fieldNames="{ label: 'name', value: 'code', children: 'children' }"
      :load-data="loadData"
      placeholder="请选择省、市、区"
      change-on-select
      :value="value"
      @change="onChange"
      :disabled="disabled"
    />
  </div>
</template>
<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    value: {
      type: Array,
      default: [],
    },
    disabled: {
      type: Boolean,
    },
    allowClear: {
      default: true,
      type: Boolean,
    },
  },
  // 数据对象
  data() {
    return {
      options: [],
      city: [],
      thisVal: null,
      valueText: ''
    };
  },
  // 事件处理器
  methods: {
    // 查询省
    FunProvince() {
      return this.$ajax({
        url: "/hxclass-management/province-manage/manage/province/" + 0,
      });
    },
    onChange(value,e) {
      if(!e) {
        this.$emit("regionCity", value);
      } else {
        this.valueText = e[0]?e[0].name:''
        this.valueText += e[1]?'/'+e[1].name:''
        this.valueText += e[2]?'/'+e[2].name:''
        this.$emit("regionCity", value);
      }
      // this.valueText = e[0]?e[0].name:''
      // this.valueText += e[1]?'/'+e[1].name:''
      // this.valueText += e[2]?'/'+e[2].name:''
      // this.$emit("regionCity", value);
    },

    // 获取修改后的文本
    txt(){
      return this.valueText
    },

    // 点击选择后触发
    loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      this.$ajax({
        url: "/hxclass-management/province-manage/manage/province/" + targetOption.code,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          setTimeout(() => {
            if (selectedOptions.length < 2) {
              res.data.forEach((item) => {
                item.isLeaf = false;
              });
            }
            targetOption.children = res.data;
            this.options = [...this.options];
          }, 150);
        } else {
          targetOption.isLeaf = true;
          this.options = [...this.options];
        }
        targetOption.loading = false;
      });
    },
    // value内容改变的时候触发
    FunValue() {
      // 查询市
      if (this.value.length > 1) {
        this.$ajax({
          url: "/hxclass-management/province-manage/manage/province/" + this.value[0],
        })
          .then((res) => {
            let thisItem = null;
            this.options.forEach((item) => {
              if (item.code == this.value[0]) {
                thisItem = item;
              }
            });
            if (res.code == 200 && res.success) {
              res.data.forEach((item) => {
                item.isLeaf = false;
              });
              thisItem.children = res.data;
              this.options = [...this.options];
            } else {
              thisItem.isLeaf = true;
              this.options = [...this.options];
            }
            this.city = thisItem;
            if (this.value.length > 2) {
              return this.$ajax({
                url: "/hxclass-management/province-manage/manage/province/" + this.value[1],
              });
            } else {
              return false;
            }
          })
          .then((res) => {
            // 查询区
            if (res) {
              let thisItem = null;
              this.city.children.forEach((item) => {
                if (item.code == this.value[1]) {
                  thisItem = item;
                }
              });
              thisItem.children = res.data;
              this.options = [...this.options];
            }
          });
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.thisVal = this.value;
    let formData = [] 
    this.FunProvince().then((res) => {
      if (res.code == 200 && res.success) {
        res.data.forEach((item) => {
            formData.push({
                name:item.name,
                code:item.code,
                isLeaf: false
            })
        });
        this.options = formData;
      }
      setTimeout(() => {
        this.FunValue();
      }, 500);
    });
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    value(e) {
      if (e.length > 0) {
        if (this.thisVal == e) {
          this.FunValue();
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.cascader{
    display: inline-block;
}
</style>
