<template>
  <div><Province @regionCity='FunProvince' :value="addressValue"></Province></div>
</template>

<script>
import Province from '@/components/provinceCity'
export default {
  // 可用组件的哈希表
  components: {Province},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      addressValue: ['110000', '110100', '110102'],  // 地址回显
    }
  },
  // 事件处理器
  methods: {
    // 获取省市区编码
    FunProvince (data) {
      this.addressValue = data
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped></style>
